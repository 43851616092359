import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { css } from "@emotion/react";
import { Form } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";

import InputMask from "react-input-mask";
import { useTranslation } from 'react-i18next';
import ApplicationService from '../../services/ApplicationService';
import { useState } from 'react';
import './MyForm.scss'


function MyForm() {
    const [pend, setPend] = useState(false)
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")

    const application = new ApplicationService()

    const onPhoneChange = (event) => {
        setPhone(event.target.value)
    }
    const onEmailChange = (event) => {
        setEmail(event.target.value)
    }
    const onNameChange = (event) => {
        setName(event.target.value)
    }
    const onMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const handleClose = () => {
        setPhone("")
        setEmail("")
        setName("")
        setMessage("")
    }

    const submit = event => {
        event.preventDefault()
        setPend(true)

        application.createApplication(phone, email, name, message).then(() => {
            handleClose()
            setPend(false)
        })
    }

    const [t] = useTranslation()

    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#ff8f27"
            },
            '& fieldset': {
                borderColor: '#adadad',
            },
            '&:hover fieldset': {
                borderColor: "#ff8f27",
            },
        },
    }

    const btnn = css`
    border-color: #ff8f27;
    width: 120px;
    min-width: 100%;
`;

    return (
        <div className='my-form'>
            <Form onSubmit={submit}>
                <Row>
                    <Col xs={12} className="mb-4">
                        <TextField
                            fullWidth
                            sx={style}
                            label={t("form-name")}
                            name="name"
                            value={name}
                            onChange={onNameChange}
                            required
                        />
                    </Col>
                    <Col xs={6} className="mb-4">
                        <InputMask
                            mask="+\9\9\8 \(99) 999-99-99"
                            maskChar={null}
                            name="number"
                            fullWidth
                            value={phone}
                            onChange={onPhoneChange}
                            required
                        >
                            {() => (
                                <TextField
                                    sx={style}
                                    label={t("form-number")}
                                />
                            )}
                        </InputMask>
                    </Col>
                    <Col xs={6} className="mb-4">
                        <TextField
                            fullWidth
                            sx={style}
                            value={email}
                            onChange={onEmailChange}
                            label="Email"
                            name="email"
                            required
                        />
                    </Col>
                    <Col xs={12} className="mb-4">
                        <TextField
                            sx={style}
                            fullWidth
                            value={message}
                            onChange={onMessageChange}
                            label={t("form-message")}
                            name="description"
                            rows={3}
                            multiline
                        />
                    </Col>
                    <div >
                        {pend ? <BeatLoader
                            className='mt-2'
                            css={btnn}
                            color={'#0db8ca'}
                            loading={true}
                            size={20}
                        /> :
                            <button
                                type="submit"
                                className='button button_orange'
                            >
                                {t("send")}
                            </button>
                        }
                    </div>
                </Row>
            </Form>
        </div>
    )
}

export default MyForm