import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ProgressBar from "@ramonak/react-progress-bar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCards } from "swiper";

import "swiper/css";
import "swiper/css/effect-cards";


import './Item.scss'

export default function Item(props) {
    const [t] = useTranslation()

    return (
        <section className='item'>

            <div className='item__header'>
                <div className='item__overlay' >
                    <h1>
                        {t("workwear")}
                    </h1>
                </div>
            </div>

            <Container>
                <Row>
                    <Col md={4} sm={8} xs className="pe-xs-4">
                        <div className='item__box'>
                            <Swiper
                                effect={"cards"}
                                grabCursor={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[EffectCards, Autoplay,]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <img src={props.image} width="100%" alt="?" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={props.image2} width="100%" alt="?" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={props.image3} width="100%" alt="?" />
                                </SwiperSlide>
                            </Swiper>
                            <div className='item__box-text'>
                                <h4>
                                    <b>{t("material")}</b> {props.material}
                                </h4>
                            </div>
                        </div>
                    </Col>

                    <Col md={8} className="ps-md-5 ps-4 pe-md-0 pe-4">
                        <div className='item__name'>
                            <h2>
                                <b>{t("activity")}</b> {props.sfera}
                            </h2>
                            <h2>
                                <b>{t("name-clothes")}</b> {props.name}
                            </h2>

                        </div>

                        <div className='item__about'>
                            <h3>
                                <b>{t("properties")}</b>

                                <span dangerouslySetInnerHTML={{ __html: props.properties }}></span>
                            </h3>

                            <h3>
                                <b>{t("density")}:</b>  {props.plotnost}
                            </h3>

                            <h3>
                                <b>{t("composition")}</b> {props.sostav}
                            </h3>

                            <h4>{t("cotton")}</h4>

                            <ProgressBar
                                completed={props.sostav1}
                                bgColor="orange"
                                height="5px"
                                labelColor="#2f2f2f"
                                transitionDuration="3s"
                                labelAlignment="outside"
                                animateOnRender
                            />
                            <h4>{t("p/e")}</h4>
                            <ProgressBar
                                completed={props.sostav2}
                                bgColor="orange"
                                height="5px"
                                labelColor="#2f2f2f"
                                transitionDuration="3s"
                                labelAlignment="outside"
                                animateOnRender
                            />


                            <h5 className='mt-4'>
                                {props.svoystva}
                            </h5>

                            <p>
                                {props.svoystvaTex}
                            </p>

                            <h5 className='mt-4'>
                                {props.advan}

                            </h5>

                            <ul>
                                <div dangerouslySetInnerHTML={{ __html: props.advanTex }}></div>
                            </ul>

                            <p dangerouslySetInnerHTML={{ __html: props.parag }}></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
