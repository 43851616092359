import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import foto1 from '../../resources/img/About/foto1.jpg'
import foto2 from '../../resources/img/About/foto2.jpg'
import foto3 from '../../resources/img/About/foto3.jpg'

import './About.scss'

export default function AboutPage() {

    const [t] = useTranslation()

    return (
        <section className='about'>

            <div className='about__header'>
                <div className='about__overlay' >
                    <h1>
                        {t("О нас")}
                    </h1>
                </div>
            </div>

            <Container className='py-5'>
                <Row>
                    <Col md={6} className="d-flex">
                        <div className='d-flex flex-column w-50'>
                            <img data-aos="fade-down-right" className='img-small' src={foto1} height="300" alt="Одежда" />
                            <img data-aos="fade-up-right" className='img-small' src={foto2} height="300" alt="Одежда" />
                        </div>
                        <img data-aos="fade-left" className='img-big' src={foto3} height="600" alt="Одежда" />
                    </Col>

                    <Col md={6}>
                        <h2 data-aos="fade-left">
                            {t("aboutpage-title")}
                        </h2>
                        <p data-aos="fade-left" className="about__parag">
                            {t("aboutpage-text")}
                        </p>

                        <Row className='about__stat'>
                            <Col xs={6} data-aos="fade-left">
                                <h6>
                                    <CheckCircleRoundedIcon /> 	{t("aboutpage1")}
                                </h6>
                                <p>
                                    - {t("aboutpage1.1")}
                                </p>
                            </Col>
                            <Col xs={6} data-aos="fade-left">
                                <h6>
                                    <CheckCircleRoundedIcon /> 	{t("aboutpage2")}
                                </h6>
                                <p>
                                    - {t("aboutpage2.1")}
                                </p>
                            </Col>
                            <Col xs={6} data-aos="fade-left">
                                <h6>
                                    <CheckCircleRoundedIcon /> 	{t("aboutpage3")}
                                </h6>
                                <p className='mb-0'>
                                    - {t("aboutpage3.1")} <br />
                                    - {t("aboutpage3.2")}

                                </p>
                            </Col>
                            <Col xs={6} data-aos="fade-left">
                                <h6>
                                    <CheckCircleRoundedIcon /> {t("aboutpage4")}
                                </h6>
                                <p className='mb-0'>
                                    {t("aboutpage4.1")}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <div className="about-counter">
                <div className='about-counter-layout'>

                    <Row>
                        <Col md={3} className="mb-4">
                            <CountUp
                                end={9}
                                duration={2.75}
                                useEasing={true}
                                useGrouping={true}
                                separator=" "
                                suffix={t("counter-year1")}
                            >
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <h6>{t("counter-year1.1")}</h6>
                        </Col>
                        <Col md={3} className="mb-4">
                            <CountUp
                                end={400}
                                duration={2.75}
                                useEasing={true}
                                useGrouping={true}
                                separator=" "
                                suffix={t("counter-year2")}
                            >
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <h6>{t("counter-year2.1")}</h6>
                        </Col>
                        <Col md={3} className="mb-4">
                            <CountUp
                                end={200}
                                duration={2.75}
                                useEasing={true}
                                useGrouping={true}
                                separator=" "
                                suffix={t("counter-year3")}
                            >
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <h6>{t("counter-year3.1")}</h6>
                        </Col>
                        <Col md={3} className="mb-4">
                            <CountUp
                                end={6}
                                duration={2.75}
                                useEasing={true}
                                useGrouping={true}
                                separator=" "
                                suffix={t("counter-year4")}
                            >
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <h6>{t("counter-year4.1")}</h6>
                        </Col>
                    </Row>

                </div>
            </div>


            <div className='productsection__bottom mt-0'>
                <div className="productsection__overlay">
                    <Container>
                        <h2>
                            {t("overlay-title")}
                        </h2>
                    </Container>
                </div>
            </div>
        </section >
    )
}
