import Item from "../Pages/Item"
import img from "../../resources/img/Clothes/abu.photographer-60.jpg"
import img2 from "../../resources/img/Clothes/abu.photographer-61.jpg"
import img3 from "../../resources/img/Clothes/abu.photographer-62.jpg"
import { useTranslation } from "react-i18next"

function Eleventh() {
    const [t] = useTranslation()
    return (
        <Item
            image={img}
            image2={img2}
            image3={img3}
            sfera={t("for-workers")}
            name={t("suit-pants")}
            material={t("eco")}
            properties={t("properties2")}
            plotnost="230gr(± 10)"
            sostav1={30}
            sostav2={70}
            svoystva={t("eco-title")}
            svoystvaTex={t("eco-text")}
            advan={t("eco-advantag")}
            advanTex={t("eco-advantag-li")}
            parag={t("eco-advantag-text")}
        />
    )
}

export default Eleventh