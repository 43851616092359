import { useState } from "react"
import { LanguageContext } from "./LanguageContext"


export default function LanguageState({ children }) {
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'ru')

    return (
        <LanguageContext.Provider value={[language, setLanguage]}>
            {children}
        </LanguageContext.Provider>
    )
}