import { useTranslation } from 'react-i18next';
import MyForm from '../Form/MyForm';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import { Col, Container, Row } from 'react-bootstrap'

import './ContactsPage.scss';

function ContactsPage() {
    const { t } = useTranslation()

    return (
        <section className='contacts'>

            <div className='contacts__header'>
                <div className='contacts__overlay' >
                    <h1>
                        {t("Контакты")}
                    </h1>
                </div>
            </div>

            <div className="contacts__contact">
                <Container>
                    <Row className="justify-content-center px-5 contact-row">
                        <Col md={6} xl={4} className="contacts__box">
                            <div>

                                <EmailIcon />
                                <br />
                                <h3>Email</h3>

                                <p>
                                    Shabnamelegant.2013@mail.ru
                                </p>
                            </div>
                        </Col>

                        <Col md={6} xl={4} className="contacts__box box2">
                            <div>
                                <PhoneIcon />
                                <br />
                                <h3>{t("contact-us")}</h3>
                                <a href="tel:+998951440006">+998 95 144 00 06</a>
                            </div>

                        </Col>

                        <Col md={6} xl={4} className="contacts__box">
                            <div>

                                <LocationOnIcon />

                                <br />
                                <h3>{t("address")}:</h3>
                                <p>
                                    {t("address-name")}
                                </p>
                            </div>

                        </Col>
                    </Row>
                    <Row className='px-5 gx-5'>
                        <Col md={6}>
                            <div className='contacts__info'>
                                <h2>
                                    {t("contacts-title")}
                                </h2>

                                <p>
                                    {t("contacts-text")}
                                </p>
                            </div>
                        </Col>

                        <Col md={6}>
                            <MyForm />
                        </Col>
                    </Row>
                </Container>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1499.2541459340898!2d69.21577284820863!3d41.27604085930694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b64ef020829%3A0x6ca929049c986e82!2zQXhzdXMga2l5aW0gLyDRgdC_0LXRhtC-0LTQtdC20LTQsCAvIFdvcmt3ZWFyIEhTRQ!5e0!3m2!1sru!2s!4v1668779281964!5m2!1sru!2s" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section >
    )
}

export default ContactsPage