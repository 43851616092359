import Item from "../Pages/Item"
import img from "../../resources/img/Clothes/abu.photographer-88.jpg"
import img2 from "../../resources/img/Clothes/abu.photographer-91.jpg"
import img3 from "../../resources/img/Clothes/abu.photographer-92.jpg"
import { useTranslation } from "react-i18next"

function Sixteenth() {
    const [t] = useTranslation()
    return (
        <Item
            image={img}
            image2={img2}
            image3={img3}
            sfera={t("for-guidance")}
            name={t("suit-pants")}
            material={t("mixture")}
            properties={t("properties2")}
            plotnost="240gr(± 10)"
            sostav1={20}
            sostav2={80}
            svoystva={t("ripstop-title")}
            svoystvaTex={t("ripstop-text")}
            advan={t("ripstop-advantag")}
            advanTex={t("ripstop-advantag-li")}
        />
    )
}

export default Sixteenth