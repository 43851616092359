import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from '../../resources/img/logo.png'
import './Footer.scss'

function Footer() {
    const { t } = useTranslation()

    return (
        <footer className="footer">
            <div className='footer__overlay'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <img src={logo} alt="Shabnam elegant" />

                            <p>
                                {t("footer-title")}
                            </p>
                        </Col>
                        <Col md={4}>
                            <h3>{t("company")}</h3>

                            <div className='footer__link'>
                                <Link to="/about">
                                    {t("О нас")}
                                </Link>
                                <Link to="/products">
                                    {t("Продукция")}
                                </Link>
                                <Link to="/productions/1">
                                    {t("Производство")}
                                </Link>
                                <Link to="/contacts">
                                    {t("Контакты")}
                                </Link>
                            </div>
                        </Col>
                        <Col md={4}>
                            <h3>
                                {t("contact-us")}
                            </h3>

                            <div className='footer__link'>
                                <a href="tel:+998951440006">+998 95 144-00-06</a>
                                <a href="tel:+998990103138">+998 99 010-31-38</a>
                                <a href="tel:+998990103136">+998 99 010-31-36</a>
                            </div>

                            <h3 className='mt-5'>
                                {t("address")}
                            </h3>

                            <div className='footer__link'>
                                <p>
                                    {t("address-name")}
                                </p>
                            </div>
                        </Col>

                        <Col className='footer__bottom'>
                            <span>
                                Shabnam elegant 2022
                            </span>

                            <a href="https://wtma.uz/">
                                Website developer wtma.uz
                            </a>

                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}

export default Footer