import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import useMenu from '../../hooks/useMenu';
import { LanguageContext } from '../../context/LanguageContext';


import logo from '../../resources/img/logo.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';

import { Container, NavDropdown } from 'react-bootstrap';

import './Header.scss';
import { Language } from '../../Language';

const Header = () => {
    const [language, setLanguage] = useContext(LanguageContext)
    const { t, i18n } = useTranslation()
    const [open, setOpen] = useState(false)
    const menuLi = useMenu()
    const lang = new Language()


    const changeLang = useCallback(lang => {
        setLanguage(lang)
        i18n.changeLanguage(lang)
    }, [])


    // Dropdown

    const handleClick = useCallback(() => {
        setOpen(open => !open)

        document.body.classList.toggle("active")
    }, [])


    return (
        <header className="header"  >

            <Container>
                <div className='header__top'>
                    <div className='header__contacts'>
                        <Link to="#">
                            <img src={logo} alt="logo" />
                        </Link>

                        <div className="header__contacts-box">
                            <PhoneIphoneIcon />
                            <div>
                                <h5>
                                    {t("contact-us")}
                                </h5>
                                <div className='header__contacts-tel'>
                                    <span>
                                        +998 95 144 00 06
                                    </span>
                                    <span>
                                        +998 99 010 31 38
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="header__contacts-box">
                            <EmailIcon />
                            <div>
                                <h5>
                                    E-mail:
                                </h5>
                                <span>
                                    Shabnamelegant.2013@mail.ru
                                </span>
                            </div>
                        </div>
                        <div className="header__contacts-box">
                            <LocationOnIcon />

                            <div>
                                <h5>
                                    {t("address")}
                                </h5>
                                <span>
                                    {t("address-name")}
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="menu">

                    <div className={open ? "menu__hamburger active" : "menu__hamburger"} onClick={handleClick}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                    {/* Logo */}



                    {/* Menu */}

                    <ul className="menu__list">
                        {
                            menuLi.getLi.map((item, index) => (
                                <li className="menu__item" key={index}>
                                    {
                                        !item.children
                                            ? (
                                                <NavLink to={item.link} className={({ isActive }) => isActive ? "active" : null}>
                                                    {item.title}
                                                </NavLink>
                                            ) : (
                                                <NavLink to={item.link} className={({ isActive }) => isActive ? "active p-0" : "p-0"}>
                                                    <NavDropdown
                                                        id="nav-dropdown-dark-example"
                                                        title={
                                                            <>{item.title}</>
                                                        }
                                                        renderMenuOnMount={true}
                                                        className="item"
                                                    >
                                                        {
                                                            item.children.map((item, i) => (
                                                                <NavLink
                                                                    to={item.link}
                                                                    className={({ isActive }) => isActive ? "dropdown-item active" : "dropdown-item"}
                                                                    key={i}
                                                                >
                                                                    {item.title}
                                                                </NavLink>
                                                            ))
                                                        }
                                                    </NavDropdown>
                                                </NavLink>
                                            )
                                    }
                                </li>
                            ))
                        }
                    </ul>

                    <div className="menu__lang">

                        {/* Language Dropdown */}

                        <NavDropdown
                           title={
                            <>
                                {
                                    // eslint-disable-next-line array-callback-return
                                    lang.getAvialableLanguages().map((lang) => {
                                        if (lang.slug === language) {
                                            return lang.title
                                        }
                                    })
                                }
                            </>
                        }
                        onSelect={(lang) => changeLang(lang)}
                        renderMenuOnMount={true}
                    >
                        {
                            lang.getAvialableLanguages()
                                .map((lang, index) => {
                                    return (
                                        language !== lang.slug
                                            ? <NavDropdown.Item key={index}
                                                                eventKey={lang.slug}>{lang.title}</NavDropdown.Item>
                                            : null
                                    )
                                })
                        }
                        </NavDropdown>
                    </div>

                    {/* Mobile Menu */}

                    <div className={open ? "mob-menu active" : "mob-menu"}>
                        <div className="mob-menu__content">
                            <div className="mob-menu__header">
                                {/* Social */}

                                <div className="mob-menu__social">
                                    <Link to="/">
                                        <span>Shabnam elegant</span>
                                    </Link>
                                </div>
                            </div>

                            <div className="mob-menu__body">
                                <ul className="mob-menu__list">
                                    {
                                        menuLi.getMobLi.map((item, index) => (
                                            <li className="mob-menu__item" key={index}>
                                                <NavLink
                                                    to={item.link}
                                                    onClick={handleClick}
                                                    className={({ isActive }) => isActive ? "active" : null}> {item.title}
                                                </NavLink>
                                            </li>
                                        ))
                                    }
                                </ul>

                                {/* <ul className="mob-menu__lang">
                                    {
                                        lang.getAvialableLanguages().map((lang) => {
                                            return (
                                                <li
                                                    key={index}
                                                    onClick={e => props.onChangeLang(e.currentTarget.textContent)}
                                                    className={data.language === lang ? "active" : null}>{lang}</li>
                                            )
                                        })
                                    }
                                </ul> */}
                            </div>
                        </div>

                        <div className="overlay" onClick={handleClick}></div>
                    </div>
                </div>
            </Container>

        </header>

    )
}

export default Header;