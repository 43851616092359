import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PhoneIcon from '@mui/icons-material/Phone';

import img1 from '../../resources/img/Productions/exp1.jpg'
import img2 from '../../resources/img/Productions/exp2.jpg'
import img3 from '../../resources/img/Productions/exp3.jpg'
import img4 from '../../resources/img/Productions/exp4.jpg'

import "swiper/css";
import './Productions.scss'
import { Link } from 'react-router-dom';

export default function Experimental() {
    const [t] = useTranslation()

    return (
        <section className='productions'>
            <div className='productions__header'>
                <div className='productions__overlay' >
                    <h1>
                        {t("Производство")}
                    </h1>
                </div>
            </div>

            <Container>
                <div className='productions__section'>
                    <Row>
                        <Col md={8}>
                            <Swiper
                                slidesPerView={1}

                                loop={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                                className="ProductSwiper"
                            >
                                <SwiperSlide>
                                    <img src={img1} width="100%" height="400px" alt="Закройный цех" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={img2} width="100%" height="400px" alt="Закройный цех" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={img3} width="100%" height="400px" alt="Закройный цех" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={img4} width="100%" height="400px" alt="Закройный цех" />
                                </SwiperSlide>
                            </Swiper>

                            <div className='productions__parag'>
                                <h2>
                                    {t("experim")}
                                </h2>

                                <p>
                                    {t("experim-text")}
                                </p>

                                <ul>
                                    <div dangerouslySetInnerHTML={{ __html: t("experim-text1") }} />
                                </ul>

                                <p>
                                    {t("experim-text2")}
                                </p>
                            </div>
                        </Col>

                        <Col md={4}>
                            <div className="productions__links">
                                <h5>
                                    {t("workshop")}
                                </h5>

                                <Link to="/productions/cutting">{t("cutting")}</Link>
                                <Link to="/productions/experimental">{t("experim")}</Link>
                                <Link to="/productions/sewing">{t("sewing")}</Link>
                            </div>

                            <div className='productions__contact'>
                                <ul>
                                    <li className='productions__contact-title'>
                                        {t("contact-box")}
                                    </li>
                                    <li className='productions__contact-address'>
                                        {t("contact-box1")} <br />
                                        {t("contact-box2")}
                                    </li>
                                    <li className='productions__contact-phone'>
                                        <a href="tel:+998951440006"> <PhoneIcon /> +998 95 144-00-06</a>
                                        <a href="tel:+998990103138"> <PhoneIcon /> +998 99 010-31-38</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}