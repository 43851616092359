import Item from "../Pages/Item"
import img from "../../resources/img/Clothes/abu.photographer-137.jpg"
import img2 from "../../resources/img/Clothes/abu.photographer-138.jpg"
import img3 from "../../resources/img/Clothes/abu.photographer-140.jpg"
import { useTranslation } from "react-i18next"

function Tort() {
    const [t] = useTranslation()
    return (
        <Item
            image={img}
            image2={img2}
            image3={img3}
            sfera={t("for-workers")}
            name={t("suit-pants")}
            material={t("eco-raincoat")}
            properties={t("properties2")}
            plotnost="230gr(± 10)"
            sostav1={30}
            sostav2={70}
            svoystva={t("raincoat-title")}
            svoystvaTex={t("raincoat-text")}
            advan={t("raincoat-advantag")}
            advanTex={t("raincoat-advantag-li")}
        />
    )
}

export default Tort