import Item from "../Pages/Item"
import img from "../../resources/img/Clothes/abu.photographer-49.jpg"
import img2 from "../../resources/img/Clothes/abu.photographer-50.jpg"
import img3 from "../../resources/img/Clothes/abu.photographer-52.jpg"
import { useTranslation } from "react-i18next"

function Ninth() {
    const [t] = useTranslation()
    return (
        <Item
            image={img}
            image2={img2}
            image3={img3}
            sfera={t("for-guidance")}
            name={t("suit-pants")}
            material={t("antistatic")}
            properties={t("properties1")}
            plotnost="240gr(± 10)"
            sostav1={60}
            sostav2={40}
            svoystva={t("antistatic-title")}
            svoystvaTex={t("antistatic-text")}
            advan={t("antistatic-advantag")}
            advanTex={t("antistatic-advantag-li")}
            parag={t("antistatic-advantag-text")}
        />
    )
}

export default Ninth