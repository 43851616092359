import Item from "../Pages/Item"
import img from "../../resources/img/Clothes/abu.photographer-93.jpg"
import img2 from "../../resources/img/Clothes/abu.photographer-96.jpg"
import img3 from "../../resources/img/Clothes/abu.photographer-97.jpg"
import { useTranslation } from "react-i18next"

function Seventeenth() {
    const [t] = useTranslation()
    return (
        <Item
            image={img}
            image2={img2}
            image3={img3}
            sfera={t("for-mod")}
            name={t("suit-pants")}
            material={t("ripstop")}
            properties={t("properties2")}
            plotnost="210gr(± 10)"
            sostav1={50}
            sostav2={50}
            svoystva={t("ripstop-title")}
            svoystvaTex={t("ripstop-text")}
            advan={t("ripstop-advantag")}
            advanTex={t("ripstop-advantag-li")}
        />
    )
}

export default Seventeenth