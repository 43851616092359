import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import img1 from '../../resources/img/Clothes/first.jpg'
import img2 from '../../resources/img/Clothes/abu.photographer-6.jpg'
import img3 from '../../resources/img/Clothes/abu.photographer-12.jpg'
import img4 from '../../resources/img/Clothes/abu.photographer-17.jpg'
import img5 from '../../resources/img/Clothes/abu.photographer-25.jpg'
import img6 from '../../resources/img/Clothes/abu.photographer-32.jpg'
import img7 from '../../resources/img/Clothes/abu.photographer-38.jpg'
import img8 from '../../resources/img/Clothes/abu.photographer-43.jpg'
import img9 from '../../resources/img/Clothes/abu.photographer-49.jpg'
import img10 from '../../resources/img/Clothes/abu.photographer-55.jpg'
import img11 from '../../resources/img/Clothes/abu.photographer-60.jpg'
import img12 from '../../resources/img/Clothes/abu.photographer-64.jpg'
import img13 from '../../resources/img/Clothes/abu.photographer-69.jpg'
import img14 from '../../resources/img/Clothes/abu.photographer-74.jpg'
import img15 from '../../resources/img/Clothes/abu.photographer-80.jpg'
import img16 from '../../resources/img/Clothes/abu.photographer-88.jpg'
import img17 from '../../resources/img/Clothes/abu.photographer-93.jpg'
import img18 from '../../resources/img/Clothes/abu.photographer-99.jpg'
import img19 from '../../resources/img/Clothes/abu.photographer-104.jpg'
import img20 from '../../resources/img/Clothes/abu.photographer-110.jpg'
import img21 from '../../resources/img/Clothes/abu.photographer-116.jpg'
import img22 from '../../resources/img/Clothes/abu.photographer-122.jpg'
import img23 from '../../resources/img/Clothes/abu.photographer-128.jpg'
import img24 from '../../resources/img/Clothes/abu.photographer-137.jpg'
import img25 from '../../resources/img/Clothes/abu.photographer-141.jpg'
import img26 from '../../resources/img/Clothes/abu.photographer-144.jpg'
import img27 from '../../resources/img/Clothes/abu.photographer-148.jpg'
import img28 from '../../resources/img/Clothes/abu.photographer-153.jpg'

import './ProductsPage.scss'




export default function ProductsPage() {
    const [t] = useTranslation()

    const data = [
        {
            id: 1,
            image: img1,
            title: t("suit-pants"),
            slug: "1"
        },
        {
            id: 2,
            image: img2,
            title: t("suit-pants"),
            slug: "2"
        },
        {
            id: 3,
            image: img3,
            title: t("suit-pants"),
            slug: "3"
        },
        {
            id: 4,
            image: img4,
            title: t("suit-pants"),
            slug: "4"
        },
        {
            id: 5,
            image: img5,
            title: t("suit-pants"),
            slug: "5"
        },
        {
            id: 6,
            image: img6,
            title: t("suit-pants"),
            slug: "6"
        },
        {
            id: 7,
            image: img7,
            title: t("suit-pants"),
            slug: "7"
        },
        {
            id: 8,
            image: img8,
            title: t("suit-halfsuit"),
            slug: "8"
        },
        {
            id: 9,
            image: img9,
            title: t("suit-pants"),
            slug: "9"
        },
        {
            id: 10,
            image: img10,
            title: t("suit-pants"),
            slug: "10"
        },
        {
            id: 11,
            image: img11,
            title: t("suit-pants"),
            slug: "11"
        },
        {
            id: 12,
            image: img12,
            title: t("suittransformer-pants"),
            slug: "12"
        },
        {
            id: 13,
            image: img13,
            title: t("suit-pants"),
            slug: "13"
        },
        {
            id: 14,
            image: img14,
            title: t("suit-pants"),
            slug: "14"
        },
        {
            id: 15,
            image: img15,
            title: t("suit-pants"),
            slug: "15"
        },
        {
            id: 16,
            image: img16,
            title: t("suit-pants"),
            slug: "16"
        },
        {
            id: 17,
            image: img17,
            title: t("suit-pants"),
            slug: "17"
        },
        {
            id: 18,
            image: img18,
            title: t("suit-halfsuit"),
            slug: "18"
        },
        {
            id: 19,
            image: img19,
            title: t("suit-pants"),
            slug: "19"
        },
        {
            id: 20,
            image: img20,
            title: t("suit-pants"),
            slug: "20"
        },
        {
            id: 21,
            image: img21,
            title: t("suit-pants"),
            slug: "21"
        },
        {
            id: 22,
            image: img22,
            title: t("suit-pants"),
            slug: "22"
        },
        {
            id: 23,
            image: img23,
            title: t("suit-pants"),
            slug: "23"
        },
        {
            id: 24,
            image: img24,
            title: t("suit-pants"),
            slug: "24"
        },
        {
            id: 25,
            image: img25,
            title: t("suit-pants"),
            slug: "25"
        },
        {
            id: 26,
            image: img26,
            title: t("suit-pants"),
            slug: "26"
        },
        {
            id: 27,
            image: img27,
            title: t("suit-pants"),
            slug: "27"
        },
        {
            id: 28,
            image: img28,
            title: t("suit-pants"),
            slug: "28"
        },
    ]



    return (
        <section className='products'>

            <div className='products__header'>
                <div className='products__overlay' >
                    <h1>
                        {t("Продукция")}
                    </h1>
                </div>
            </div>

            <Container>
                <Row className='mt-5'>
                    {data.map(item =>
                        <Col key={item.id} md={4}>
                            <div className='productsection__box'>
                                <Link to={`/products/${item.slug}`}>
                                    <img src={item.image} width="100%" height="500" alt="?" />
                                </Link>
                                <h3>
                                    {item.title}
                                </h3>
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>
        </section>
    )
}
