export class Language {
    constructor(languages) {
        this.languages = languages || [
            {
                title: 'En',
                slug: 'en'
            },
            {
                title: 'Ru',
                slug: 'ru'
            }
        ]
    }

    getAvialableLanguages() {
        return this.languages
    }
}