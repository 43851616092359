import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollTop from "../ScrollTop/ScrollTop";
import ScrollToTop from 'react-scroll-to-top';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import AboutPage from '../Pages/About';
import ProductsPage from '../Pages/ProductsPage';
import Cutting from '../Pages/Cutting';
import ContactsPage from '../Pages/ContactsPage';
import Page404 from '../404/404';

import First from '../Items/One';
import Second from '../Items/Two';
import Third from '../Items/Three';
import Fourth from '../Items/Four';
import Fifth from '../Items/Five';
import Sixth from '../Items/Six';
import Seventh from '../Items/Seven';
import Eighth from '../Items/Eight';
import Ninth from '../Items/Nine';
import Tenth from '../Items/Ten';
import Eleventh from '../Items/Eleven'
import Twelwth from '../Items/Twelve'
import Thirthteenth from '../Items/Thirteen'
import Fourthing from '../Items/Fourteen'
import Fifteenth from '../Items/Fifteen'
import Sixteenth from '../Items/Sixteen'
import Seventeenth from '../Items/Seventeen'
import Eighteenth from '../Items/Eighteen'
import Ninteenth from '../Items/Nineteen'
import Twentyth from '../Items/Twenty'
import Bir from '../Items/Bir'
import Ikki from '../Items/Ikki'
import Uch from '../Items/Uch'
import Tort from '../Items/Tort'
import Besh from '../Items/Besh'
import Olti from '../Items/Olti'
import Yetti from '../Items/Yetti'
import Sakkiz from '../Items/Sakkiz'
import Toqqiz from '../Items/Toqqiz'
import Experimental from '../Pages/Experimental';
import Sewing from '../Pages/Sewing';
import LanguageState from '../../context/LanguageState';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import '../../styles/_main.scss';
import '../../styles/Buttons.scss';
import '../../styles/Forms.scss';
import '../../styles/Various.scss';

const MainPage = lazy(() => import('../Pages'))

function App() {

    return (
        <Suspense fallback={null}>
            <LanguageState>
                <Router>
                    <Header />
                    <main>
                        <ScrollTop>
                            <Routes>
                                <Route exact path="/" element={<MainPage />} />
                                <Route exact path="/about" element={<AboutPage />} />
                                <Route path="/products" element={<ProductsPage />} />

                                <Route exact path="/products/1" element={<First />} />
                                <Route exact path="/products/2" element={<Second />} />
                                <Route exact path="/products/3" element={<Third />} />
                                <Route exact path="/products/4" element={<Fourth />} />
                                <Route exact path="/products/5" element={<Fifth />} />
                                <Route exact path="/products/6" element={<Sixth />} />
                                <Route exact path="/products/7" element={<Seventh />} />
                                <Route exact path="/products/8" element={<Eighth />} />
                                <Route exact path="/products/9" element={<Ninth />} />
                                <Route exact path="/products/10" element={<Tenth />} />
                                <Route exact path="/products/11" element={<Eleventh />} />
                                <Route exact path="/products/12" element={<Twelwth />} />
                                <Route exact path="/products/13" element={<Thirthteenth />} />
                                <Route exact path="/products/14" element={<Fourthing />} />
                                <Route exact path="/products/15" element={<Fifteenth />} />
                                <Route exact path="/products/16" element={<Sixteenth />} />
                                <Route exact path="/products/17" element={<Seventeenth />} />
                                <Route exact path="/products/18" element={<Eighteenth />} />
                                <Route exact path="/products/19" element={<Ninteenth />} />
                                <Route exact path="/products/20" element={<Twentyth />} />
                                <Route exact path="/products/21" element={<Bir />} />
                                <Route exact path="/products/22" element={<Ikki />} />
                                <Route exact path="/products/23" element={<Uch />} />
                                <Route exact path="/products/24" element={<Tort />} />
                                <Route exact path="/products/25" element={<Besh />} />
                                <Route exact path="/products/26" element={<Olti />} />
                                <Route exact path="/products/27" element={<Yetti />} />
                                <Route exact path="/products/28" element={<Sakkiz />} />
                                <Route exact path="/products/29" element={<Toqqiz />} />

                                <Route exact path="/productions/cutting" element={<Cutting />} />
                                <Route exact path="/productions/experimental" element={<Experimental />} />
                                <Route exact path="/productions/sewing" element={<Sewing />} />
                                <Route exact path="/contacts" element={<ContactsPage />} />
                                <Route path="*" element={<Page404 />} />
                            </Routes>
                        </ScrollTop>
                    </main>
                    <ScrollToTop smooth top="400" color="#ff8f27" />
                    <Footer />
                </Router>
            </LanguageState>
        </Suspense>

    );
}

export default App